@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap";
:root {
  --text-main: black;
  --text-muted: var(--text-main);
  --border: var(--text-main);
  --links: var(--text-main);
  --button-base: var(--text-main);
  --button-hover: #0000;
  --animation-duration: .3s;
}

body {
  max-width: 87.5rem;
  margin: 1.25rem auto;
  padding: 0 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: calc(14px + .2vw);
  font-weight: 400;
  line-height: 1.75;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .75rem;
  line-height: 1;
}

h1 {
  margin-top: 1.25rem;
  font-size: 2.488rem;
}

h2 {
  font-size: 2.074rem;
}

h3 {
  font-size: 1.728rem;
}

h4 {
  font-size: 1.44rem;
}

h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

a {
  text-decoration: underline;
}

p, ul {
  margin-bottom: 1rem;
}

p:last-child, ul:last-child {
  margin-bottom: 0;
}

section {
  border: 2px solid var(--border);
  box-shadow: 10px 10px 0px var(--border);
  margin: 1.5rem 0;
  padding: 1.25rem;
}

footer {
  border-top-width: 2px;
}

nav {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

input[type="email"], textarea {
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 0;
  width: 100%;
}

button, input[type="submit"] {
  color: #fff;
  border: 2px solid #000;
  border-radius: 0;
}

button:hover, input[type="submit"]:hover {
  color: #000;
}

#welcome {
  background-color: #fff;
}

#welcome svg {
  width: 40%;
  max-width: 12rem;
  height: auto;
  margin: .625rem auto 0;
  display: block;
}

#who-i-am {
  background-color: #abdef6;
}

#how-i-got-here {
  background-color: #e4d3ff;
}

#what-i-do {
  background-color: #fcb5c5;
}

#other-projects {
  background-color: #d7f4dd;
}

#connect-with-me {
  background-color: #ffcb46;
}

.is-invalid input, .is-invalid textarea {
  border-color: #b20000;
}

.form-message {
  display: none;
}

.form-message--success, .form-message--error {
  margin-bottom: .625rem;
  padding: 10px;
  display: block;
}

.form-message--success {
  color: #191970;
  background: #87cefa;
}

.form-message--error {
  color: #b20000;
  background-color: #ffa07a;
}

.text-error {
  color: #b20000;
}

@media screen and (width >= 43.75rem) {
  body {
    margin: 0 auto;
    padding: 1.25rem 1.25rem 0;
  }

  main {
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    display: grid;
  }

  section {
    margin: 0;
  }

  footer {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }
}

@media screen and (width >= 67.5rem) {
  main {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/*# sourceMappingURL=index.e0e5984f.css.map */
