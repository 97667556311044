@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
  --text-main: black;
  --text-muted: var(--text-main);
  --border: var(--text-main);
  --links: var(--text-main);
  --button-base: var(--text-main);
  --button-hover: rgba(0, 0, 0, 0);
  --animation-duration: 0.3s;

}

body {
  font-family: 'Poppins', sans-serif;
  font-size: calc(14px + 0.2vw);
  font-weight: 400;
  max-width: 87.5rem;
  margin: 1.25rem auto;
  padding: 0 1.25rem;
  line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 2.488rem;
  margin-top: 1.25rem;
  ;
}

h2 {
  font-size: 2.074rem;
}

h3 {
  font-size: 1.728rem;
}

h4 {
  font-size: 1.44rem;
}

h5 {
  font-weight: 500;
  font-size: 1.2rem;
}

a {
  text-decoration: underline;
}

p,
ul {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

section {
  padding: 1.25rem;
  margin: 1.5rem 0;
  border: 2px solid var(--border);
  box-shadow: 10px 10px 0px var(--border);
}

footer {
  border-top-width: 2px;
}

nav {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

input[type="email"],
textarea {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0;
  border: 2px solid black;
}

button,
input[type="submit"] {
  color: white;
  border-radius: 0;
  border: 2px solid black;

  &:hover {
    color: black;
  }
}

#welcome {
  background-color: white;

  svg {
    display: block;
    width: 40%;
    height: auto;
    max-width: 12rem;
    margin: 0.625rem auto 0;
  }
}

#who-i-am {
  background-color: #ABDEF6;
}

#how-i-got-here {
  background-color: #E4D3FF;
}

#what-i-do {
  background-color: #fcb5c5;
}

#other-projects {
  background-color: #D7F4DD;
}

#connect-with-me {
  background-color: #FFCB46;
}

.is-invalid {

  input,
  textarea {
    border-color: #b20000;
  }
}

.form-message {
  display: none;

  &--success,
  &--error {
    display: block;
    margin-bottom: 0.625rem;
    padding: 10px;
  }

  &--success {
    color: midnightblue;
    background: lightskyblue;
  }

  &--error {
    color: #b20000;
    background-color: lightsalmon;
  }
}

.text-error {
  color: #b20000;
}

@media screen and (min-width: 43.75rem) {
  body {
    margin: 0 auto;
    padding: 1.25rem 1.25rem 0;
  }

  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  section {
    margin: 0;

  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 67.5rem) {
  main {
    grid-template-columns: 1fr 1fr 1fr;
  }
}